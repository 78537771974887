import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo1 from "./logo.png"; // Replace with your actual logo paths
import logo2 from "./logo2.png";
import "./App.css";

function Home() {
  const [currentLogo, setCurrentLogo] = useState(logo1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLogo((prevLogo) => (prevLogo === logo1 ? logo2 : logo1));
    }, 1000); // Change image every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return (
    <div className="App">
      <>
        <div
          style={{
            color: "yellow",
            fontSize: "40px",
            position: "fixed",
            top: "20px",
            left: "20px",
          }}
        >
          Smoking Filet-O-Fish
        </div>
        <header className="App-header">
          <img
            src={currentLogo}
            className="App-logo"
            alt="happy-meal"
            // onClick={handleClick} // Optional click handler if you need it
          />
          <Link to="/gallery">
          <div
            className="clickable-div"
            style={{ color: "yellow", marginTop: "50px" }}
            // onClick={handleClick}
          >
            Memes
          </div>
          </Link>
          <div style={{ marginTop: "40px" }}>
            <a
              href="https://t.me/smokingfishfillet"
              target="_blank"
              rel="noopener noreferrer"
              className="clickable-div"
              style={{ color: "yellow", marginRight: "20px" }}
            >
              <i className="fab fa-telegram-plane"></i> Telegram
            </a>
            {/* <a
              href="https://x.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "yellow" }}
              className="clickable-div"
            >
              <i className="fab fa-twitter"></i> Twitter
            </a> */}
          </div>
        </header>
      </>
    </div>
  );
}

export default Home;
