import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./App.css";

const images = [
  "image1.png",
  "image2.png",
  "image3.png",
  "image4.png",
  "image5.png",
  "image6.png",
  "image7.png",
  "image8.png",
  "image9.png",
  "image10.png",
  "image11.png",
  "image12.png",
  "image13.png",
  "image14.png",
  "image15.png",
  "image16.png",
  "image17.png",
  "image18.png",
  "image19.png",
  "image20.png",
  "image21.png",
  "image22.png",
  "image23.png",
  "image24.png",
  "image25.png",
  "image26.png",
];

function Home() {
  return (
    <div className="App-gallery">
      <header className="App-header-gallery">
        <h1 className="top-text font1">SMOKING-FILET-O-FISH</h1>
        <Link to="/">
          <button className="crazy-button">
            <span className="text">Return Home</span>
          </button>
        </Link>
      </header>

      <div className="photo-grid">
        {images.map((image, index) => (
          <div key={index} className="photo-grid-item">
            <LazyLoadImage
              src={image}
              alt={`Gallery ${index + 1}`}
              effect="blur" // You can choose other effects like 'opacity' or 'black-and-white'
            />
          </div>
        ))}
      </div>
      <div className="footer-text font1 text-center">
        SMOKING-FILET-O-FISH is a meme coin with no intrinsic value or
        expectation of financial return. It is not affiliated with mcdonalds in
        any way. webdev tg- @bsbasebs - for hire- also if like the site/ made
        money on this work for burgers-
        6aDN7Hds5agiRNmCcnJvQFiFFKTRDD166ZVpZ6P7Bz4G
      </div>
    </div>
  );
}

export default Home;
